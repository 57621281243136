// 
// _popover.scss
//

.popover {
    box-shadow: $popover-box-shadow;

    .popover-header{
        color: var(--#{$prefix}body-color) !important;
    }
}
