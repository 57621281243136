//
// root.scss
//

@mixin color-mode($mode: light, $root: false) {
    @if $color-mode-type == "media-query" {
        @if $root ==true {
            @media (prefers-color-scheme: $mode) {
                :root {
                    @content;
                }
            }
        } @else {
            @media (prefers-color-scheme: $mode) {
                @content;
            }
        }
    } @else {
        [data-bs-theme="#{$mode}"] {
            @content;
        }
    }
}

//theme-light
:root,
[data-bs-theme="light"] {
    --#{$prefix}header-bg: #ffffff;
    --#{$prefix}header-item-color: #{$header-item-color};
    --#{$prefix}header-item-sub-color: #{$text-muted};
    --#{$prefix}header-height: 70px;

    --#{$prefix}rightbar-width: 280px;
    --#{$prefix}topnav-bg: #{$white};

    --#{$prefix}boxed-layout-width: 1300px;

    --#{$prefix}menu-dark-item-color:#727e8f;

    --#{$prefix}footer-height: 60px;
    --#{$prefix}footer-bg: #ffffff;

    [data-topbar="light"] {
        --#{$prefix}topbar-search-bg: #f1f5f7;
    }

    --#{$prefix}header-dark-bg: #{$white};

    --#{$prefix}menu-item-color: #7f8399;
    --#{$prefix}menu-item-active-color: #5664d2;

    --#{$prefix}input-bg: #{$white};
    // Topbar User
    --#{$prefix}topbar-search-bg: #f3f3f9;

    // Horizontal nav
    --#{$prefix}topnav-bg: #{$white};
    --#{$prefix}topnav-item-color: #{darken($gray-600, 10%)};
    --#{$prefix}topnav-item-color-active: var(--#{$prefix}primary);

    // twocolumn menu
    --#{$prefix}twocolumn-menu-iconview-bg: #{$white};
    --#{$prefix}twocolumn-menu-bg: #{$white};

    --#{$prefix}header-item-color: #555b6d;

    --#{$prefix}boxed-body-bg: #eaedf2;

    [data-topbar="dark"] {
        --#{$prefix}header-dark-bg: #040622;
        --#{$prefix}topnav-item-color: #c3cbe4;
        --#{$prefix}header-dark-item-color: #707182;
    }

    //sidebar vertical light
    --#{$prefix}sidebar-bg: #ffffff;
    --#{$prefix}sidebar-border-color: #e9ebef;
    --#{$prefix}sidebar-menu-item-color: #7b8190;
    --#{$prefix}sidebar-menu-sub-item-color: #7b8190;
    --#{$prefix}sidebar-menu-item-icon-color: #7b8190;
    --#{$prefix}sidebar-menu-item-hover-color: #383c40;
    --#{$prefix}sidebar-menu-item-active-color: #3b76e1;
    --#{$prefix}sidebar-menu-sub-item-active-color: #3b76e1;
    --#{$prefix}sidebar-menu-item-active-bg-color: #f2f3fe;

    [data-sidebar="light"] {
        --#{$prefix}sidebar-bg: #ffffff;
        --#{$prefix}sidebar-menu-item-color: #707182;
        --#{$prefix}sidebar-menu-sub-item-color: #7c8a96;
        --#{$prefix}sidebar-menu-item-icon-color: #505d69;
        --#{$prefix}sidebar-menu-item-hover-color: #383c40;
        --#{$prefix}sidebar-menu-item-active-color: #556ee6;
    }

    [data-sidebar="brand"] {
          --#{$prefix}sidebar-dark-menu-item-color: #ffffff;
    }

    [data-sidebar="dark"] {
        --#{$prefix}sidebar-dark-bg: #040622; //2c313a
        --#{$prefix}sidebar-dark-menu-item-color: #707182;
        --#{$prefix}sidebar-dark-menu-sub-item-color: #626373;
        --#{$prefix}sidebar-dark-menu-item-icon-color: #525363;
        --#{$prefix}sidebar-dark-menu-item-hover-color: #ffffff;
        --#{$prefix}sidebar-dark-menu-item-active-color: #ffffff;
        --#{$prefix}sidebar-dark-menu-item-active-bg-color: #043655;
    }
}

//theme dark
@if $enable-dark-mode {
    @include color-mode(dark, true) {
        --#{$prefix}light: #{$light-dark};
        --#{$prefix}light-rgb: #{to-rgb($light-dark)};
        --#{$prefix}dark: #{$light-dark};
        --#{$prefix}dark-rgb: #{to-rgb($light-dark)};

        // header
        --#{$prefix}header-bg: #{$gray-800};
        --#{$prefix}header-dark-bg: #{$gray-800};
        --#{$prefix}header-item-color: #d9e3f3;
        --#{$prefix}topbar-search-bg: #f3f3f912;

        .table-light {
            --#{$prefix}table-color: #8c9fb9;
            --#{$prefix}table-bg: var(--#{$prefix}tertiary-bg);
            --#{$prefix}table-border-color: var(--#{$prefix}border-color);
            --#{$prefix}table-striped-bg: var(--#{$prefix}tertiary-bg);
            --#{$prefix}table-striped-color: #{lighten($light-dark, 100%)};
            --#{$prefix}table-active-bg: var(--#{$prefix}tertiary-bg);
            --#{$prefix}table-active-color: #{lighten($light-dark, 100%)};
            --#{$prefix}table-hover-bg: var(--#{$prefix}tertiary-bg);
            --#{$prefix}table-hover-color: #{lighten($light-dark, 100%)};
        }

        &[data-topbar="colored"] {
            --#{$prefix}header-colored-bg: #556ee6;
            --#{$prefix}topbar-search-bg: #2b324412;
        }

        &[data-topbar="dark"] {
            --#{$prefix}header-dark-bg: #22354e;
            --#{$prefix}topnav-bg: #{$gray-800};
            --#{$prefix}topnav-item-color: #c3cbe4;
            --#{$prefix}header-dark-item-color: #d9e3f3;
        }

        &[data-topbar="light"] {
            --#{$prefix}header-bg: #{$gray-800};
            --#{$prefix}header-dark-item-color: #d9e3f3;
            --#{$prefix}topnav-bg: #{$gray-800};
            --#{$prefix}header-item-color: #d9e3f3;

        }

        &[data-sidebar="colored"] {
            --#{$prefix}topbar-search-bg: #2b3244;
        }

        // horizontal nav
        // --#{$prefix}topnav-bg:              #282e3f;

        --#{$prefix}header-item-sub-color: #{$text-muted};
        // footer
        --#{$prefix}footer-bg: #{$gray-800};

        --#{$prefix}boxed-body-bg: #283f5c;
        --#{$prefix}input-bg: #162436;

        --#{$prefix}sidebar-dark-bg: #22354e; //2c313a
        --#{$prefix}sidebar-dark-menu-item-color: #727e8f;
        --#{$prefix}sidebar-dark-menu-sub-item-color: #727e8f;
        --#{$prefix}sidebar-dark-menu-item-icon-color: #727e8f;
        --#{$prefix}sidebar-dark-menu-item-hover-color: #b6c5d9;
        --#{$prefix}sidebar-dark-menu-item-active-color: #b6c5d9;
        --#{$prefix}sidebar-dark-menu-item-active-bg-color: #043655;

        &[data-sidebar="dark"] {
            --#{$prefix}sidebar-dark-bg: #22354e; //2c313a
            --#{$prefix}sidebar-dark-menu-item-color: #707182;
            --#{$prefix}sidebar-dark-menu-sub-item-color: #727e8f;
            --#{$prefix}sidebar-dark-menu-item-icon-color: #727e8f;
            --#{$prefix}sidebar-dark-menu-item-hover-color: #b6c5d9;
            --#{$prefix}sidebar-dark-menu-item-active-color: #b6c5d9;
            --#{$prefix}sidebar-dark-menu-item-active-bg-color: #043655;

            // --#{$prefix}topbar-search-bg: #f3f3f9;
        }
    }
}
